import React from 'react';
import { fabric } from 'fabric';

interface ExportButtonProps {
  fabricCanvas: fabric.Canvas | null;
}

const ExportButton: React.FC<ExportButtonProps> = ({ fabricCanvas }) => {
  const handleExport = () => {
    if (fabricCanvas) {
      // Deselect all objects
      fabricCanvas.discardActiveObject().renderAll();

      // Export the canvas
      const dataURL = fabricCanvas.toDataURL({ format: 'png' });
      const link = document.createElement('a');
      link.href = dataURL;
      link.download = 'custom.png';
      link.click();
    }
  };

  return (
    <button className={'btn btn-dark btn-download'} onClick={handleExport}>Download</button>
  );
};

export default ExportButton;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { Card, Row, Col, Image } from 'react-bootstrap';
import './CanvasDetail.css';


interface Remix {
  id: number;
  remixed_mock_up_image_url: string;
  secondary_image_url: string;

  // include other properties here
}
interface Canvas {
  cover_image_url: string;
  // include other properties of the canvas object here
}

type User = {
  username: string;
  full_name: string;
  image: string;
};

const CanvasRemixesList = () => {
  const [remixes, setRemixes] = useState<Remix[]>([]);
  const { id: canvasId } = useParams<{ id: string }>();
  const [canvas, setCanvas] = useState<Canvas | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true); // Add this line  
  const [hoveredCard, setHoveredCard] = useState<number | null>(null);

  
  const active_url = process.env.REACT_APP_FLOSSY_API_URL;
  
  
  useEffect(() => {
    const url = `${active_url}/canvas/${canvasId}/remixes/`;
    const token = 'a24e549e62d35efe610192dd98355e96e1888ceb'; // replace 'your_token' with your actual token
    setLoading(true); // Add this line
    
    axios.get(url, {
      headers: { 'Authorization': `Token ${token}` }
    })
      .then(response => {
        setRemixes(response.data);
        console.log(response.data);
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
        setLoading(false); // Set loading to false even if there is an error

      });
  }, [canvasId]);

  useEffect(() => {
    const token = 'a24e549e62d35efe610192dd98355e96e1888ceb'; 
    axios.get(`${active_url}/canvases/${canvasId}/`, {
      headers: { 'Authorization': `Token ${token}` }
    })
      .then(response => {
        setCanvas(response.data);
  
        // Make the second API call
        axios.get(`${active_url}/users/${response.data.user}/`, {
          headers: { 'Authorization': `Token ${token}` }
        })
          .then(response => {
            setUser(response.data);
          })
          .catch(error => {
            console.error('Error fetching user data: ', error);
          });
      })
      .catch(error => {
        console.error('Error fetching canvas data: ', error);
      });
  }, []);

  if (loading || !canvas || !user) {
    return<div style={{ marginTop: '60px' }}>
    <Row style={{ marginBottom: '20px' }}>
    <Col>
        <div style={{ color: '#7b7b7b', fontSize: '16px', textAlign: 'center' }}>
          <Image src={'https://placehold.jp/fafafa/fafafa/60x60.png'}  roundedCircle width={60} height={60} className="grey-circle" />
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className="artist-name-placeholder"></div>
            <div className="artist-description-placeholder"></div>
          </div>

          {/* <p>@{user ? user.username : 'Loading...'}</p> */}

        </div>
        
      </Col>
    </Row>
    <Row>
    <div className="artist-name" style={{marginBottom:'5px', color: '#7b7b7b'}}> - </div>
    </Row>
    <Row className="g-1">
      <Col xs={12} sm={6} md={6} lg={4} xl={3}>

        <Card  className="create-card">
        <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Image src={'https://placehold.jp/fafafa/fafafa/2000x2000.png'}   width={180} height={180}  style= {{boxShadow: '5px 5px 35px 5px rgba(0, 0, 0, 0.25)'}} />
                  <Card.Text style={{ color: 'white' }}>
          </Card.Text>
        </Card.Body>
        </Card>
      </Col>
      {Array.from({ length: 11 }).map((_, index) => (
        <Col xs={12} sm={6}  md={6} lg={4} xl={3} key={index}>
          <Card style={{ backgroundColor: '#FAFAFA', height: '400px', border: 'none',  borderRadius: '0px' }}>
            <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <Card.Img variant="top" src={'https://placehold.jp/fafafa/fafafa/2000x2000.png'} alt="Remix" />
              <Card.Text>
                {/* Remix ID: {remix.id} */}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  </div>
  }

  return (
    <div style={{ marginTop: '60px' }}>
      <Row style={{ marginBottom: '20px' }}>
      <Col>
          <div style={{ color: '#7b7b7b', fontSize: '16px', textAlign: 'center' }}>
            <Image src={user ? user.image : 'default-image.png'} roundedCircle width={60} height={60} />
            <div className="artist-name" style={{textAlign:'center'}}>{user ? user.full_name : 'Loading...'}
            <img src="https://s3-flossyapp.s3.eu-north-1.amazonaws.com/verification.svg" alt="Verification Badge" style={{ height: '1em', marginLeft: '5px' }} />
            </div>
            <div className="artist-description" style={{textAlign:'center'}}>{user ? user.username : 'Loading...'}</div>
            

            {/* <p>@{user ? user.username : 'Loading...'}</p> */}

          </div>
          
        </Col>
      </Row>
      <Row>
      <div className="artist-name" style={{marginBottom:'5px', color: '#7b7b7b'}}>Showing {remixes.length} community creations</div>
      </Row>
      <Row className="g-1">
        <Col xs={12} sm={6} md={6} lg={4} xl={3}>

          <Link to={`/canvas/${canvasId}`} key={canvasId}>
          <Card  className="create-card">
          <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Image src={canvas ? canvas.cover_image_url : 'default-image.png'}  width={180} height={180}  style= {{boxShadow: '5px 5px 35px 5px rgba(0, 0, 0, 0.25)'}} />
                    <Card.Text style={{ color: 'white' }}>
                    <button className={'btn btn-dark btn-download'}>Create your own Merch</button>
            </Card.Text>
          </Card.Body>
          </Card>
          </Link>
        </Col>
        {remixes.map(remix => (
          <Col xs={12} sm={6}  md={6} lg={4} xl={3} key={remix.id}>
            <Link to={`/remix/${remix.id}`}>
            <Card 
              style={{ backgroundColor: hoveredCard === remix.id ? '#fafafa' : '#FAFAFA', height: '400px', border: 'none',  borderRadius: '0px' }}
              onMouseEnter={() => setHoveredCard(remix.id)}
              onMouseLeave={() => setHoveredCard(null)}
            >
                <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <Card.Img variant="top" src={hoveredCard === remix.id ? remix.secondary_image_url : remix.remixed_mock_up_image_url} alt="Remix" />
                  <Card.Text>
                    {/* Remix ID: {remix.id} */}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default CanvasRemixesList;
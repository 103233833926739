//src/pages/CheckoutForm.tsx
import React, { useCallback } from "react";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { useLocation } from 'react-router-dom';

import { Container, Row, Col } from 'react-bootstrap';
import '../components/CanvasDetail.css';


// const stripe_public_key = process.env.REACT_APP_STRIPE_PUBLIC_KEY?.trim();

// if (!stripe_public_key) {
//   throw new Error("REACT_APP_STRIPE_PUBLIC_KEY is not defined in your environment variables");
// }

// const stripePromise = loadStripe(stripe_public_key);


// const stripePromise = loadStripe("pk_test_51PiCrFL3o7LdvNz13wmVEzAMl77B6z32b4AFm8jbfbkaf5UoJBSMj3ahCcCHt7j8UuHwCAanW1qxHCyceMxHeK9v00ax0J1z7t");
const stripePromise = loadStripe("pk_live_51PiCrFL3o7LdvNz16XTHvrt2rcLmYDN3oqFt1dQZcHKybA0oPQFsXP8Df54aSZBQKpyhg5CQQGgGjOktRJMQxBiK003XXinqY6");



const CheckoutForm = () => {
  const location = useLocation();
  const { baseProduct, remix, size } = location.state;
  const active_url = (process.env.REACT_APP_FLOSSY_API_URL || '').replace('/api', '');

  const fetchClientSecret = useCallback(() => {
    return fetch(`${active_url}/create-checkout-session/`, {      
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ baseProduct, remix, size })
    })
    .then((res) => res.json())
    .then((data) => data.clientSecret);
  }, [baseProduct, remix, size]);

  const options = {fetchClientSecret};

  return (
    <Container fluid>
      <Row> 
        <Col xs={12}>
          <Row style={{ marginTop: '40px' }}>

            <div id="checkout">
              <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={options}
              >
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
            </div>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default CheckoutForm;

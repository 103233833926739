import React from 'react';
import { useNavigate } from 'react-router-dom'; // Change this import
// import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

interface ShareButtonProps {
  fabricCanvas: {
    toDataURL: () => string;
  };
  canvasTitle: string | null;
  canvasUser: string | null;
}


const ShareButton: React.FC<ShareButtonProps> = ({ fabricCanvas, canvasTitle, canvasUser }) => {
  const navigate = useNavigate(); // Change this line
  const active_url = process.env.REACT_APP_FLOSSY_API_URL;
  const shareText = `I just made a remix of ${canvasUser}'s ${canvasTitle} on Flossy`;
  // const shareImage = fabricCanvas.toDataURL(); // Convert the canvas to an image URL

  const handleShare = () => {
    // Convert the canvas to an image
    const canvasImage = fabricCanvas.toDataURL();
  
    // Send a POST request to the backend
    axios.post(`${active_url}/share/`, {
      image: canvasImage,
    })
      .then(response => {
        const data = response.data;
    
        // Use the Web Share API to share the image
        if (navigator.share) {
          navigator.share({
            title: canvasTitle || 'Untitled',
            text: `I just made a remix of ${canvasUser}'s ${canvasTitle} on Flossy`,
            url: data.share_url,
            files: [new File([canvasImage], 'canvasImage.png', { type: 'image/png' })]
          })
          .catch((error) => console.log('Error sharing', error));
        } else {
          navigate(`/share/${data.imageId}`, { state: { canvasImage, shareText} });

        }
      });
  };




  return (
      <button className={'btn btn-dark btn-download'} onClick={handleShare}>Share Design</button>
  );
};

export default ShareButton;

import React from 'react';


const FramerPage = () => {
    return (
      <iframe 
        src="https://inspirational-taxonomy-289465.framer.app/" 
        style={{ 
          width: '100%', 
          height: '100vh', 
          border: 'none',
          overflow: 'hidden'  // This will hide the scrollbar
        }}
        title="Framer Page"
        scrolling="yes"  // This will disable scrolling
      />
    );
  }
  
  export default FramerPage;
// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import MainLayout from './components/MainLayout'; // Import MainLayout here
import Canvases from './pages/canvases';
import CanvasDetail from './pages/canvasdetail';
import CreateRemixView from './pages/CreateRemixView';
import CanvasRemixesListView from './pages/CanvasRemixesListView';
import RemixDetailView from './pages/RemixDetailView';
import CheckoutForm from './pages/CheckoutForm';  // Import CheckoutForm
import Return from './pages/Return';  // Import Return
import SharePage from './pages/SharePage'; // Import the new component
import 'bootstrap/dist/css/bootstrap.min.css';
import * as amplitude from '@amplitude/analytics-browser';
import FramerPage from './pages/FramerPage'; // Import the new component


// Initialize Amplitude with your API key from the environment variable
amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY!);



function App() {
  return (
    <Router>
      <MainLayout>
        <Routes>
        <Route path="/" element={<FramerPage />} />
        <Route path="@westislonely" element={<Navigate to="/canvas/1/remixes" replace />} />
          <Route path="/canvases" element={<Canvases />} />
          <Route path="/canvas/:id" element={<CanvasDetail />} />
          <Route path="/create-remix" element={<CreateRemixView />} />
          <Route path="/canvas/:id/remixes" element={<CanvasRemixesListView />} />
          <Route path="/remix/:id" element={<RemixDetailView />} />
          <Route path="/checkout" element={<CheckoutForm />} /> 
          <Route path="/return" element={<Return />} /> 
          <Route path="/share/:id" element={<SharePage />} />
        </Routes>
      </MainLayout>
    </Router>
  );
}

export default App;
// CreateRemixView.tsx
import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import '../components/CanvasDetail.css';
import { FaInstagram } from 'react-icons/fa';
import * as amplitude from '@amplitude/analytics-browser';



const CreateRemixView = () => {
  const location = useLocation();
  const { remixId, remixedMockUpImageUrl, canvasId } = location.state;
  // const prev_url = window.location.origin;
  const active_url = process.env.REACT_APP_FLOSSY_API_URL;
  const token = 'a24e549e62d35efe610192dd98355e96e1888ceb';
  const [creatorId, setCreatorId] = useState(''); // Initialize creatorId state
  const [isSaved, setIsSaved] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [savedCreatorId, setSavedCreatorId] = useState('');

  const handleUpdate = async () => {
    
    const data = {
      creator_id: creatorId,
      remix_id: remixId
    };

    try {
      const response = await axios.put(`${active_url}/remix/${remixId}/`, data, {
        headers: { 'Authorization': `Token ${token}` },
      });

      // Handle response
      console.log(response.data);
      setCreatorId(data.creator_id); // Update the creatorId state
      setIsSaved(true);
      setIsTyping(false);
      setSavedCreatorId(creatorId);



    } catch (error) {
      // Handle error
      console.error('Update failed', error);
    }
  };

  useEffect(() => {
    amplitude.track('Create Remix Page Viewed');
    // Add the class when the component mounts
    document.body.classList.add('full-page-background');

    // Remove the class when the component unmounts
    return () => {
      document.body.classList.remove('full-page-background');
    };
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  return (
    <Container fluid style={{ backgroundColor: '#FAFAFA', height: '100vh' }}>
      <Row> 
        <Col xl={{ span: 4, offset: 4 }} lg={{ span: 6, offset: 3 }} md={{ span: 8, offset: 2 }}  sm={{ span: 10, offset: 1 }}  xs={12}>
          <Row style={{ marginTop: '40px' }}>
            <div className="artist-name">Your Remix is ready! 🎉</div> 
            <div className="artist-description">You can enter your instagram so we know you made it</div> 

            <div className="artist-instagram-link">
            {/* <span><FaInstagram size={16} color="#333" /></span> */}
            <a className={`link-badge ${isSaved ? 'link-badge-saved' : ''}`} href={`https://instagram.com/${creatorId}`}>
            {isSaved ? (
            <img className="icon" src="https://s3-flossyapp.s3.eu-north-1.amazonaws.com/Instagram_logo_2016.svg" alt="Instagram logo" style={{ width: '16px', height: '16px', marginRight: '5px' }} />
          ) : (
            <FaInstagram className="icon" style={{ width: '16px', height:'16px', marginRight:'5px' }} />
          )}
              {savedCreatorId ? `@${savedCreatorId}` : `@${creatorId}`}
            </a>     
        </div> 
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col xs={9} style={{margin:'0', padding:'0'}}>
            <input 
              type="text" 
              placeholder='Put in your Instagram ID' 
              style={{ width: '100%', textAlign: 'center',  border: '1px solid black',   padding: '5px',   backgroundColor: 'white',   fontSize: '14px',   color: 'black', borderRadius:'4px'}} 
              value={creatorId} 
              onChange={(e) => {
                setCreatorId(e.target.value);
                setIsTyping(true);
              }}
            />
            </Col>
            <Col xs={3} style={{margin:'0', padding:'0'}}>
            <button className={`btn ${isTyping ? 'btn-dark' : 'btn-istyping'}`} onClick={handleUpdate} style={{width:'100%',fontSize:'12px'}}>Save</button>            </Col>
          </Row>
          <Row>
            {/* delete row */}
          </Row>
          <Row>         
            <div className="canvas-section">
              <img className="image" src={remixedMockUpImageUrl} alt="Remixed Mock Up" />
            </div>
          </Row>

          <Row>
            <Link to={`/remix/${remixId}`} className="btn btn-dark button">
              Buy
            </Link>
          </Row>
          <Row>
            <Link to={`/canvas/${canvasId}/remixes`} className="btn btn-dark button">
              View Remixes
            </Link>
          </Row>
          <Row>
            <Link to={`/canvas/${canvasId}/`} className="btn btn-dark button">
              Try Again
            </Link>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateRemixView;
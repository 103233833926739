import React, { useEffect } from 'react';
import CanvasRemixesList from '../components/CanvasRemixesList';
import * as amplitude from '@amplitude/analytics-browser';

const App = () => {
  useEffect(() => {
    amplitude.track('Canvas Remixes Page Viewed');
  }, []);

  return (
    <div>
      <h1></h1>
      <CanvasRemixesList />
    </div>
  );
};

export default App;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Container, Row, Col,Image } from 'react-bootstrap';
import './CanvasDetail.css';
import Accordion from 'react-bootstrap/Accordion';
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';




interface Remix {
  id: number;
  user: string;
  canvas: string;
  base_product: string;
  personalisation_text: string;
  remixed_canvas_image_url: string;
  remixed_mock_up_image_url: string;
  price:number;
  creator_id: string;
  // include other properties here
}



interface BaseProduct {
  // Define the properties of the base product here
  description: string;
  price: number;
  name: string;
}




const active_url = process.env.REACT_APP_FLOSSY_API_URL;
const FixedRow = styled(Row)`
  @media (max-width: 992px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }
`;




const RemixDetail = () => {
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const [remix, setRemix] = useState<Remix | null>(null);
  const [baseProduct, setBaseProduct] = useState<BaseProduct | null>(null);
  const [size, setSize] = useState('');
  const [otherRemixes, setOtherRemixes] = useState<Remix[]>([]);
  const [showWarning, setShowWarning] = useState(false);
  const [productImageUrls, setProductImageUrls] = useState<string[]>([]);


  const fetchBaseProduct = async (baseProductId: string) => {
    const response = await axios.get(`${active_url}/baseproduct/${baseProductId}/`);
    setBaseProduct(response.data);
  };

  useEffect(() => {
    const fetchRemix = async () => {
      const token = 'a24e549e62d35efe610192dd98355e96e1888ceb'; // replace 'your_token' with your actual token
      const response = await axios.get(`${active_url}/remix/${id}/`, {
        headers: {
          Authorization: `Token ${token}`
        }
      });
      setRemix(response.data);
      console.log(response.data);
      const productImageUrls = response.data.graphics.map((graphic: { product_image: string }) => graphic.product_image);
      setProductImageUrls(productImageUrls);
      
       // Fetch the list of remixes for the canvas
      const remixesResponse = await axios.get(`${active_url}/canvas/${response.data.canvas}/remixes/`, {
        headers: {
          Authorization: `Token ${token}`
        }
      });
      const otherRemixes = remixesResponse.data.filter((r: Remix) => r.id !== response.data.id).slice(0, 4);
      setOtherRemixes(otherRemixes);
    };
  
    fetchRemix();
  }, [id]);

  useEffect(() => {
    if (remix && remix.base_product) {
      fetchBaseProduct(remix.base_product);
    }
  }, [remix]);
  
    return (
      <Container>
        <Row style={{ marginTop: '40px' }}>
          <Col xl={6} lg={6} xs={12}>
          <Carousel className="custom-carousel">
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={remix ? remix.remixed_mock_up_image_url : 'https://placehold.jp/fafafa/fafafa/2000x2000.png'}
                alt="First slide"
              />
            </Carousel.Item>
            {productImageUrls.map((url, index) => (
              <Carousel.Item key={index}>
                  <img
                      className="d-block w-100"
                      src={url}
                      alt={`Slide ${index + 2}`}
                  />
              </Carousel.Item>
            ))}
          </Carousel>

          </Col>
          <Col xl={6} lg={6} xs={12}>
          <Row style={{ marginTop: '40px' }}>
                <Col xs={2}>
                  <div className="image-container">
                  <Link to={remix ? `/canvas/${remix.canvas}/remixes` : '#'}>
                    <Image 
                      src={'https://s3-flossyapp.s3.eu-north-1.amazonaws.com/West-profile.png'} 
                      alt="Remix image" 
                      roundedCircle 
                      style={{ width: '60px', height: '60px' }}
                    />  
                  </Link>         
                  </div> 
                </Col>
                <Col xl={10} xs={10}>
                  <div className="artist-name">Westisl0nely
                    <img src="https://s3-flossyapp.s3.eu-north-1.amazonaws.com/verification.svg" alt="Verification Badge" style={{ height: '1em', marginLeft: '5px' }} />
                </div> 



                  <div className="artist-description">Westisl0nely</div> 
                    <div className="artist-instagram-link">
                    {remix && remix.creator_id && <span style ={{fontSize: '12px', fontFamily:'Space Grotesk'}}> Created by </span>}
                    <a 
                      className={`link-badge ${remix && remix.creator_id ? 'link-badge-saved' : ''}`} 
                      href={remix && remix.creator_id ? `https://www.instagram.com/${remix.creator_id}` : '#'}
                    >
                      {remix && remix.creator_id ? (
                        <> 
                          <img className="icon" src="https://s3-flossyapp.s3.eu-north-1.amazonaws.com/Instagram_logo_2016.svg" alt="Instagram logo" style={{ width: '12px', height: '12px', marginRight: '5px' }} />
                          {remix.creator_id}
                        </>
                      ) : ''}
                    </a>           
                  </div> 
                </Col>
              </Row>
          


            <Row style={{ marginTop: '10px' }}>
                  <div>
                    {baseProduct && (
                      <div>


                      </div>
                    )}
                    {/* Display other information here */}
                  </div>
                  

              </Row>
              <Row>
                <Col xl={12} lg={12} md={12} xs={12}>
              <div>
                    {baseProduct && (
                      <div className="description-box">
                      {/* <p className="description-header">Product Description</p> */}
                      <h2 className="artist-name">Remixed {baseProduct.name}</h2>
                        <p className="artist-description">£{baseProduct.price}</p>
                      <p>{baseProduct.description}</p>
                    </div>
                    )}
                    {/* Display other information here */}
                  </div>
                  </Col>
              </Row>

            <Row>

            <Accordion className="mt-2">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Size Guide
                </Accordion.Header>
                <Accordion.Body>
                  {/* Delivery information goes here */}
                  Please use the charts below as a rough guideline to measurements through all international sizings. Please note sizes may vary between Brand and Designer as reflection of their individual look and fit, we do our best to give a detailed description of fit next to each item, however if you are unsure about a particular fit please contact us at before placing your order and we will do our best to supply you with the info you require.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Delivery and returns
                </Accordion.Header>
                <Accordion.Body>
                It typically takes 2-5 day to create the product, and 3-12 days to deliver the product depending on where in the world the destination is. Currently we only offer refunds for damaged or mislabeled products, not for buyer&apos;s remorse. It&apos;s difficult to offer returns for buyers remorse as each product is personally made.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>        
            </Row>
            <FixedRow style={{marginTop:'20px'}}>
            <form onSubmit={(e) => {
                e.preventDefault();
                if (!size) {
                  setShowWarning(true);
                } else {
                  setShowWarning(false);
                  navigate('/checkout', { state: { baseProduct, remix, size } });
                }
              }}>
                {/* <p className="artist-description">Select Size</p> */}
                <select 
                  className="form-select" 
                  aria-label="Default select example" 
                  value={size} 
                  onChange={(e) => {
                    setSize(e.target.value);
                    setShowWarning(false);
                  }}
                >
                  <option value="">Select Size</option>
                  <option value="S">Small</option>
                  <option value="M">Medium</option>
                  <option value="L">Large</option>
                  <option value="XL">Extra Large</option>
                </select>
                {showWarning && <p>Please select a size ✌️</p>}
                <button type="submit" className="btn btn-dark w-100">
                  Buy Now
                </button>
              </form>
            </FixedRow>
          </Col>
        </Row>
        <Row style={{ marginTop: '100px', marginBottom: '20px' }}>
          <Col>More Community Remixes </Col>
          </Row>
        <Row className="g-1">
        {otherRemixes.map((remix: Remix) => (
        <Col xs={12} sm={6} md={6} lg={4} xl={3} key={remix.id}>
          <Link to={`/remix/${remix.id}`}>
            <Card style={{ backgroundColor: '#FAFAFA', height: '400px', border: 'none',  borderRadius: '0px' }}>
              <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Card.Img variant="top" src={remix.remixed_mock_up_image_url} alt="Remix" />
                <Card.Text>
                  {/* Remix ID: {remix.id} */}
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </Col>
      ))}
        </Row>
      </Container>

    );
  };
  
  export default RemixDetail;
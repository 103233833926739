// src/components/MainLayout.tsx
import React, { ReactNode } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

interface MainLayoutProps {
  children: ReactNode;
}

function MainLayout({ children }: MainLayoutProps) {
  return (
    <div className="main-layout">
      <Container> {/* Use the Container component here */}
        <Row>
          <Col md={12}>
            {children}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default MainLayout;
import React, { useState, useEffect, FormEvent, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { fabric } from 'fabric';
import BaseProductSelectWithImages from './BaseProductSelectWithImages';
import Canvas from './Canvas';
import CreateRemixButton from './CreateRemixButton';
import { Container, Row, Col, Image, Form } from 'react-bootstrap';
import './CanvasDetail.css';
import { LayerConfig } from './types';
import ProgressBar from './ProgressBar';
import * as amplitude from '@amplitude/analytics-browser';

interface Canvas {
  id: number;
  note: string;
  base_products: number[];
  title: string; // Add this line
  // include other properties here
}

interface User {
  username: string;
  bio: string;
  full_name: string;
  image: string;

  // include other properties as needed
}

const CanvasDetail: React.FC = () => {
  const [canvas, setCanvas] = useState<Canvas | null>(null);
  const [layersConfigJson, setLayersConfigJson] = useState<LayerConfig[]>([]);
  
  const [personalisation, setPersonalisation] = useState('');
  const personalisationInputRef = useRef<HTMLInputElement | null>(null);
  const [baseProduct, setBaseProduct] = useState(''); // this should be a string representing the selected base product's id
  const [isBaseProductSelected, setIsBaseProductSelected] = useState(false);

  const handleBaseProductChange = (baseProduct: string) => {
    setBaseProduct(baseProduct);
    setIsBaseProductSelected(true);
  };
  
  
  
  const { id } = useParams();

  const canvasRef = useRef<fabric.Canvas | null>(null);
  const canvasContainerRef = useRef<HTMLDivElement | null>(null);

  const [step, setStep] = useState(1);


  const nextStep = () => setStep(prev => prev + 1);
  // const prevStep = () => setStep(prev => prev - 1);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  const active_url = process.env.REACT_APP_FLOSSY_API_URL;




  useEffect(() => {
    const token = 'a24e549e62d35efe610192dd98355e96e1888ceb'; 
    axios.get(`${active_url}/canvases/${id}/`, {

      headers: { 'Authorization': `Token ${token}` }
    })
      .then(response => {
        setCanvas(response.data);
        
  
        // Fetch the user data from the user URL
        axios.get(`${active_url}/users/${response.data.user}/`, {
        // axios.get(`http://localhost:8000/api/users/${response.data.user}/`, {

          headers: { 'Authorization': `Token ${token}` }
        })
          .then(response => {
            setUser(response.data);
          })
          .catch(error => {
            console.error(error);
          });
  
        // Fetch the actual JSON data from the canvas_config URL
        axios.get(response.data.canvas_config)
          .then(response => {
            setLayersConfigJson(response.data);
            setIsLoading(false);
          })
          .catch(error => {
            console.error(error);
          });
      })
      .catch(error => {
        console.error(error);
      });
  }, [id]);

  useEffect(() => {
    if (canvasContainerRef.current) {
      const width = 441
      if (canvasRef.current) {
        canvasRef.current.setWidth(width);
        canvasRef.current.setHeight(width);
      }
    }
  }, [canvasContainerRef, canvasRef]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
  
    // Track the form submission event
    const eventProperties = {
      canvasId: canvas?.id,
      personalisation: personalisation,
      baseProduct: baseProduct,
    };
    amplitude.track('Create Remix Form Submitted', eventProperties);
  
    const token = 'a24e549e62d35efe610192dd98355e96e1888ceb';     
    axios.post(`${active_url}/remix/`, {
      canvas: canvas?.id,
      personalisation: personalisation,
      base_products: [baseProduct], 
    }, {
      headers: { 'Authorization': `Token ${token}` }
    })
    .then(() => {
      // handle success
    })
    .catch(error => {
      console.error(error);
    });
  };

  // Use an effect to focus the personalisation input when the component mounts
  useEffect(() => {
    const timer = setTimeout(() => {
      if (personalisationInputRef.current) {
        personalisationInputRef.current.focus();
      }
    }, 100);
  
    // Cleanup function to clear the timeout if the component unmounts before the timeout finishes
    return () => clearTimeout(timer);
  }, []);

    if (!canvas) {
      return<Container>
      <Row>
        <Col xl={{ span: 6, offset: 3 }} lg={{ span: 8, offset: 2 }} md={{ span: 10, offset: 1 }} xs={12} sm={12}> 
          <form onSubmit={handleSubmit}>
            {step === 1 && (
              <>
              <Row>
              <ProgressBar step={step} />
              </Row>
              <Row style={{ marginTop: '40px', paddingBottom: '20px' }}>
                <Col xs={12}>
                  <div className="artist-name"></div> 
                </Col>
              </Row>
              
              <Row style={{ marginTop: '20px' }}>
                <Col xs={2}>
                  <div className="image-container">
                  <Image src={'https://placehold.jp/fafafa/fafafa/60x60.png'}  roundedCircle width={60} height={60} className="grey-circle" />
                  </div> 
                </Col>
                <Col xs={10}>
                <div className="artist-name-placeholder"></div>
                <div className="artist-description-placeholder"></div>


                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={{ span: 10, offset: 2}}>
                <div className="artist-note-placeholder"></div>                
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={{ span: 10, offset: 2}}>
                  <div className="form-input">
                    <Form.Group>
                      <Form.Control 
                      />
                    </Form.Group>
                  </div>
                </Col>
              </Row>
                <Row style={{ paddingBottom: '20px', marginTop: '20px', paddingTop: '20px' }}>
                <Col xs={12} sm={{ span: 10, offset: 2}}>
                <div ref={canvasContainerRef}>
                      <img 
                        src={'https://placehold.jp/fafafa/fafafa/500x500.png'} 
                        alt="Canvas placeholder" 
                        style={{ width: '500px', height: '500px' }}
                      />
                  </div>
                  </Col>
                </Row>

              </>
            )}
            {step === 2 && (
              <>
              <Row>
              <ProgressBar step={step} />
              </Row>
  
              </>
            )}
          </form>
          {/* <DynamicBackgroundAudio /> */}
        </Col>
      </Row>
    </Container>
    }


  return (
    <Container>
      <Row>
        <Col xl={{ span: 6, offset: 3 }} lg={{ span: 8, offset: 2 }} md={{ span: 10, offset: 1 }} xs={12} sm={12}> 
          <form onSubmit={handleSubmit}>
            {step === 1 && (
              <>
              <Row>
              <ProgressBar step={step} />
              </Row>
              <Row style={{ marginTop: '40px', paddingBottom: '20px' }}>
                <Col xs={12}>
                  <div className="artist-name"></div> 
                </Col>
              </Row>
              
              <Row style={{ marginTop: '20px' }}>
                <Col xs={2}>
                  <div className="image-container">
                    <Image src={user ? user.image : 'Loading...'} roundedCircle className="profile-image" />
                  </div> 
                </Col>
                <Col xs={10}>
                <div className="artist-name">
                {user ? user.full_name : 'Loading...'}
                  <img src="https://s3-flossyapp.s3.eu-north-1.amazonaws.com/verification.svg" alt="Verification Badge" style={{ height: '1em', marginLeft: '5px' }} />
                </div>
                  <div className="artist-description">@{user ? user.username : 'Loading...'}</div> 
                  {/* <div className="artist-instagram-link"><a className="link-badge" href="https://www.instagram.com/westisl0nely/?hl=en">@westisl0nely</a></div>    */}
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={{ span: 10, offset: 2}}>
                  <p><div className="artist-note">{canvas.note}</div></p>                  
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={{ span: 10, offset: 2}}>
                  <div className="form-input">
                    <Form.Group>
                      <Form.Control 
                        ref={personalisationInputRef}
                        value={personalisation} 
                        onChange={e => setPersonalisation(e.target.value)} 
                        placeholder="Start typing here!"
                        className="personalisation-input"
                      />
                    </Form.Group>
                  </div>
                </Col>
              </Row>
                <Row style={{ paddingBottom: '20px', marginTop: '20px', paddingTop: '20px' }}>
                <Col xs={12} sm={{ span: 10, offset: 2}}>
                  <div ref={canvasContainerRef}>
                    {!isLoading && user && <Canvas personalisation={personalisation} canvasRef={canvasRef} layersConfig={layersConfigJson as LayerConfig[]} canvasTitle={canvas.title} canvasUser={user.full_name}/>}
                  </div>
                  </Col>
                </Row>
                <Row>
                  <button 
                    type="button" 
                    onClick={() => {
                      amplitude.track('Next Step Button Clicked');
                      nextStep();
                    }}
                    className={`btn btn-dark choose-form-button ${personalisation ? 'show-button' : ''}`}
                  >
                    Next Step {'>'} choose merch 👕
                  </button>
                </Row>
                <Row>
                  <div style={{ height: '100px' }}></div>
                </Row>

              </>
            )}
            {step === 2 && (
              <>
              <Row>
              <ProgressBar step={step} />
              </Row>
            <Row style={{ marginTop: '40px' }}>
              <Col xs={12} className="center-content">
                <div className="artist-name">Choose Your item</div> 
                <div className="artist-description">Click One</div> 
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="center-content">
                <label>
                  <BaseProductSelectWithImages
                    baseProductIds={canvas.base_products}
                    handleBaseProductChange={handleBaseProductChange}
                  />
                </label>
              </Col>
            </Row>
                {/* <button type="button" onClick={prevStep}>Back</button> */}
                {isBaseProductSelected && canvasRef.current && 
                  <CreateRemixButton 
                    personalisation={personalisation} 
                    fabricCanvas={canvasRef.current} 
                    baseProduct={baseProduct}
                    canvasId={canvas.id}
                  />
                }
              </>
            )}
          </form>
          {/* <DynamicBackgroundAudio /> */}
        </Col>
      </Row>
    </Container>
  );
}

export default CanvasDetail;
